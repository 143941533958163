"use client";

import { Link } from "@/components/Link/Link.component";
import type { JobModel } from "@/services/api/content/models/job.model";
import clsx from "clsx";
import NextImage from "next/image";
import { useTrackJobAdInview } from "../hooks/use-track-job-ad-inview.hook";
type JobItemProps = {
  readonly className?: string;
  readonly id: number;
  readonly image: JobModel.Image | null;
  readonly headline: string;
  readonly placement: string;
  readonly targetUrl: string;
};
export function JobItem({
  className,
  headline,
  id,
  image,
  placement,
  targetUrl
}: JobItemProps) {
  const ref = useTrackJobAdInview({
    jobAdId: id,
    placement
  });
  return <Link className={clsx(["space-y-1 block group/job", className])} href={targetUrl} openInNewTab={true} ref={ref} rel="noreferrer" data-sentry-element="Link" data-sentry-component="JobItem" data-sentry-source-file="JobItem.component.tsx">
      {image ? <figure className="relative block h-[60px] max-w-[160px]">
          <NextImage alt={image.alternativeText} className="h-[60px] w-full object-contain object-left" height="60" src={image.thumbnailUrl} width="160" />
        </figure> : null}

      <p className="text-[1rem] leading-snug text-balance line-clamp-3 group-hover/job:underline">
        {headline}
      </p>
    </Link>;
}